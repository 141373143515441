import { Component, Input } from '@angular/core';

import { QuestAnswerComponent } from './quest-answer.component';

/**
 * @translate card.recommend
 */

@Component({
    selector: 'vi-quest-answer-card',
    templateUrl: './quest-answer-card.component.html',
    styleUrls: ['./quest-answer-card.component.scss'],
})
export class QuestAnswerCardComponent extends QuestAnswerComponent {
    description: string;
    hideDescription = true;

    @Input() multi = false;

    isSelected(id: string): boolean {
        return this.multi
            ? this.control.value && (this.control.value as Array<string>).includes(id)
            : this.control.value === id;
    }

    select(id: string, description?: string, event?: Event) {
        if (this.config.behavior.alwaysShowPrevNext) {
            this.description = description;
        }
        // Prevent default scroll down behavior of space press
        if (event instanceof KeyboardEvent && event.code === 'Space') {
            event.preventDefault();
        }

        if (this.multi) {
            let newValue: string[];
            let removed = false;
            if (this.control.value && Array.isArray(this.control.value)) {
                const current = this.control.value as Array<string>;
                if (current.includes(id)) {
                    removed = true;
                    newValue = current.filter((val) => val !== id);
                } else {
                    newValue = [...current, id];
                }
            } else {
                newValue = [id];
            }
            this.control.setValue(newValue);
            this.change.emit({ value: id, removed, valid: !this.question.required || !!newValue.length });
        } else {
            if (this.control.value === id) {
                // unselect
                if (this.config.behavior.alwaysShowPrevNext) {
                    this.hideDescription = true;
                }
                this.control.setValue(null);
                this.change.emit({ value: null, valid: !this.question.required });
            } else {
                if (this.config.behavior.alwaysShowPrevNext) {
                    this.hideDescription = !this.description;
                }
                this.control.setValue(id);
                this.change.emit({ value: id, valid: true });
            }
        }
    }
}
