<ng-container *ngIf="model">
    <!-- not for checkbox/file/hint as it is rendered by answer component -->
    <ng-container *ngIf="model.type !== 'checkbox' && model.type !== 'file' && model.type !== 'hint'">
        <h4>
            <vi-quest-validation-icon
                *ngIf="config.icons.enabled"
                [error]="invalid || model.error"
                [checking]="checking"
                [disabled]="model.disabled"
            ></vi-quest-validation-icon>
            <span [innerHTML]="model.text"></span>
            <span *ngIf="model.unit"> ({{ model.unit }})</span>
            <vi-quest-hint
                *ngIf="model.hint"
                [model]="model.hint"
                [focused]="checking ? false : focused"
            ></vi-quest-hint>
        </h4>
        <div *ngIf="model.description" [innerHTML]="model.description | trustHtml"></div>
    </ng-container>

    <ng-container *ngIf="config.behavior.disableOnCheck && checking && model.type === 'checkbox'">
        <mat-spinner mode="indeterminate" [diameter]="25"></mat-spinner>
    </ng-container>

    <vi-quest-answer-card
        *ngIf="model.type === 'card'"
        [disabled]="model.disabled || checking"
        [question]="this"
        [id]="model.id"
        [model]="model.options"
        [control]="control"
        [multi]="model.multi"
        [class.disabled]="model.disabled || checking"
        (change)="changed($event)"
    >
    </vi-quest-answer-card>
    <vi-quest-answer-text
        *ngIf="model.type === 'text' || model.type === 'multitext'"
        [placeholder]="model.placeholder"
        [disabled]="model.disabled || checking"
        [question]="this"
        [id]="model.id"
        [model]="model.options"
        [control]="control"
        [class.disabled]="model.disabled || checking"
        (change)="changed($event)"
    >
    </vi-quest-answer-text>
    <vi-quest-answer-suggest
        *ngIf="model.type === 'suggest'"
        [placeholder]="model.placeholder"
        [disabled]="model.disabled || checking"
        [question]="this"
        [id]="model.id"
        [model]="model.options"
        [control]="control"
        [class.disabled]="model.disabled || checking"
        (change)="changed($event)"
    >
    </vi-quest-answer-suggest>
    <vi-quest-answer-dropdown
        *ngIf="model.type === 'dropdown'"
        [placeholder]="model.placeholder"
        [disabled]="model.disabled || checking"
        [question]="this"
        [id]="model.id"
        [model]="model.options"
        [control]="control"
        [class.disabled]="model.disabled || checking"
        (change)="changed($event)"
    >
    </vi-quest-answer-dropdown>
    <vi-quest-answer-radio
        *ngIf="model.type === 'radio'"
        [disabled]="model.disabled || checking"
        [question]="this"
        [id]="model.id"
        [model]="model.options"
        [control]="control"
        [class.disabled]="model.disabled || checking"
        (change)="changed($event)"
    >
    </vi-quest-answer-radio>
    <vi-quest-answer-checklist
        *ngIf="model.type === 'checklist'"
        [disabled]="model.disabled || checking"
        [question]="this"
        [id]="model.id"
        [model]="model.options"
        [control]="control"
        [class.disabled]="model.disabled || checking"
        (change)="changed($event)"
    >
    </vi-quest-answer-checklist>
    <!-- so, then it takes question as answer-option-like -->
    <vi-quest-answer-checkbox
        *ngIf="model.type === 'checkbox'"
        [disabled]="model.disabled || checking"
        [question]="this"
        [id]="model.id"
        [model]="model"
        [hint]="model.hint"
        [control]="control"
        [class.disabled]="model.disabled || checking"
        (change)="changed($event)"
    >
    </vi-quest-answer-checkbox>
    <vi-quest-answer-date
        *ngIf="model.type === 'date'"
        [placeholder]="model.placeholder"
        [max]="model.validate?.dateMax"
        [min]="model.validate?.dateMin"
        [withoutDay]="model.date?.withoutDay"
        [disabled]="model.disabled || checking"
        [question]="this"
        [id]="model.id"
        [model]="model.options"
        [control]="control"
        [class.disabled]="model.disabled || checking"
        (change)="changed($event)"
    >
    </vi-quest-answer-date>
    <vi-quest-answer-number
        *ngIf="model.type === 'float' || model.type === 'integer'"
        [placeholder]="model.placeholder"
        [disabled]="model.disabled || checking"
        [question]="this"
        [id]="model.id"
        [model]="model.options"
        [control]="control"
        [type]="model.type"
        [class.disabled]="model.disabled || checking"
        (change)="changed($event)"
    >
    </vi-quest-answer-number>
    <vi-quest-answer-file
        *ngIf="model.type === 'file'"
        [text]="model.text"
        [description]="model.description"
        [options]="model.upload"
        [disabled]="model.disabled || checking"
        [question]="this"
        [id]="model.id"
        [template]="model.template"
        [error]="model.error"
        [validate]="model.validate"
        [control]="control"
        [class.disabled]="model.disabled || checking"
        (change)="changed($event)"
        [placeholderImage]="model.placeholderImage"
    >
    </vi-quest-answer-file>
    <vi-quest-answer-hint
        *ngIf="model.type === 'hint'"
        [id]="model.id"
        [disabled]="model.disabled || checking"
        [question]="this"
        [headline]="model.text"
        [description]="model.description"
        [text]="model.value"
        [error]="invalid || model.error"
        [unit]="model.unit"
        [hint]="model.hint"
        [isFocused]="checking ? false : focused"
    ></vi-quest-answer-hint>
    <div
        *ngIf="model.type === 'empty'"
        [fxFlex]="model.appearance === 'half-standalone' ? 50 : 0"
        fxHide
        fxShow.gt-sm
    ></div>
</ng-container>
