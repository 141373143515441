<ng-container *ngIf="model">
    <div class="wizard-body">
        <div class="wizard-content">
            <ng-container *ngFor="let question of model[0].questions; trackBy: id">
                <vi-quest-question
                    attr.quest-selector="{{ question.id | lowercase }}"
                    *ngIf="question?.id === upcoming?.id"
                    [model]="question"
                    [error]="question.error"
                    (check)="answer($event)"
                    (textChange)="textChanged($event)"
                    [checking]="checking"
                >
                </vi-quest-question>
            </ng-container>

            <re-captcha
                *ngIf="
                    last === true &&
                    config?.behavior?.useCaptcha &&
                    (!config?.captcha?.version || config?.captcha?.version === 'v2')
                "
                [site_key]="config.captcha?.siteKey"
                [language]="lang"
                [size]="config.captcha?.size"
                [theme]="config.captcha?.theme"
                [type]="config.captcha?.type"
                (captchaResponse)="handleCaptcha($event)"
                (captchaExpired)="expired()"
            >
            </re-captcha>

            <div
                class="wizard-btn-group btn-group-prev-next"
                *ngIf="config?.behavior?.alwaysShowPrevNext || showNextButton"
            >
                <button
                    mat-raised-button
                    type="button"
                    class="btn-prev"
                    [color]="current ? 'primary' : 'default'"
                    (click)="prev(true)"
                    [disabled]="questionIndex === 0 || checking"
                    *ngIf="
                        (config?.behavior?.alwaysShowPrevNext && questionIndex !== 0) ||
                        (!config?.behavior?.alwaysShowPrevNext && !showNextButton)
                    "
                >
                    {{ prefixed('button.' + 'prev') | translate }}
                </button>
                <button
                    mat-raised-button
                    type="button"
                    class="btn-next"
                    [color]="current ? 'primary' : 'default'"
                    *ngIf="
                        config?.behavior?.alwaysShowPrevNext ||
                        ((current?.value || current?.question?.type === 'hint') &&
                            showNextButton &&
                            (current?.question?.multi || !checking))
                    "
                    (click)="next(true)"
                    [disabled]="
                        (!current?.value && current?.question?.type !== 'hint') ||
                        !current.valid ||
                        checking ||
                        !!current.question.error
                    "
                >
                    {{ prefixed('button.' + ((last === true && 'submit') || 'next')) | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="wizard-footer" *ngIf="config?.behavior?.wizardFooter">
        <mat-progress-bar class="bar" mode="determinate" [value]="progress" color="warn"></mat-progress-bar>

        <div fxLayoutAlign="space-between">
            <button
                class="navigation"
                color="default"
                [disabled]="questionIndex === 0 || checking"
                (click)="prev(true)"
                mat-mini-fab
                type="button"
            >
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>

            <p *ngIf="progress === 100">{{ prefixed('progressbar.completed') | translate }}</p>

            <button
                class="navigation"
                mat-mini-fab
                type="button"
                [color]="current ? 'primary' : 'default'"
                (click)="next(true)"
                [disabled]="
                    (!current?.value && current?.question?.type !== 'hint') ||
                    !showNextButton ||
                    checking ||
                    !!current.question.error
                "
            >
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
        </div>
    </div>
</ng-container>
