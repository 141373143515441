import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { OnChanges, SimpleChanges } from '@angular/core';
import { ReCaptchaComponent } from 'angular2-recaptcha';
import { TranslateService } from '@ngx-translate/core';

import { BrowserUtils as browser } from '../utils/browser.utils';
import { ThrottledEventEmitter } from '../utils/throttled-event-emitter';
import { QuestConfig } from '../quest-config.interface';
import { Prefixable } from '../prefixable.interface';
import { QuestPart } from '../quest-part.interface';
import { QuestQuestion } from '../quest-question.interface';
import { QuestQuestionGroup } from '../quest-question-group.interface';
import { QuestValueChange } from '../quest-value-change.interface';
import { QuestPartChange } from '../quest-part-change.interface';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { WizardStepChange } from '../wizard-step-change.interface';

/**
 * Abstract for specialized part rendering - @see appropriate for details.
 *
 * @param selected Part to show first
 * @param model Quest part model
 *
 * @event partChange
 * @event check
 *
 * @translate button.prev
 * @translate button.next
 * @translate button.submit
 *
 * @note Uses Element.closest for scrolling to invalid element on next/submit
 *       For IE support you need install shim element-closest (and add to polyfills)
 */

const flatten = (arr: any[][]) => arr.reduce((prev, curr) => prev.concat(curr), []);
@Component({
    template: '', // just "abstract" for specified types
})
export class QuestPartsComponent extends Prefixable implements OnInit, OnChanges, AfterViewInit {
    @Input() checking?: boolean;
    @Input() markErrors = false;

    @Input() selected = 0;
    @Input() model: QuestPart[];
    @Input() progress: number;

    @Output() partChange: EventEmitter<QuestPartChange> = new ThrottledEventEmitter();
    @Output() partReset: EventEmitter<QuestPart> = new EventEmitter();
    @Output() check: EventEmitter<QuestValueChange> = new EventEmitter();
    @Output() leave: EventEmitter<string> = new EventEmitter();
    @Output() step: EventEmitter<WizardStepChange> = new EventEmitter();

    @ViewChild(ReCaptchaComponent) captcha: ReCaptchaComponent;

    lang: string;
    private element: HTMLElement;
    private pending: { action?: () => void };

    submitDisabled: boolean;

    constructor(
        public config: QuestConfig,
        private recaptchaV3Service: ReCaptchaV3Service,
        private translate: TranslateService,
        element: ElementRef
    ) {
        super(config && config.context);
        this.element = element.nativeElement;
    }

    ngOnInit(): void {
        this.lang = this.translate.currentLang;
        this.submitDisabled = false;
    }

    ngOnChanges(changed: SimpleChanges): void {
        if (this.markErrors) {
            // deferred as run in on-change
            setTimeout(() => this.markNonValidParts());
        }
        // process pending check if behavior set
        if (changed.model && this.config.behavior.waitForCheck) {
            // deferred as run in on-change
            setTimeout(() => this.processPending());
        }
    }

    ngAfterViewInit(): void {
        this.scrollToTop();
    }

    changed(answer: QuestValueChange): void {
        if (!answer.valid) {
            // do not emit if answer not valid and behavior set to not check invalid
            if (!this.config.behavior.checkInvalid) {
                return;
            }
            // update question value to have data for completed check in place, before is back in model
            answer.question.value = answer.value;
        }

        // set pending check if behavior set
        if (this.config.behavior.waitForCheck) {
            this.setPending();
        }
        this.check.emit({ ...answer, part: this.model[this.selected] });
    }

    next(run?: boolean): void {
        // wait until potential check
        if (!run && this.config.behavior.waitForCheck) {
            return this.setPending(() => this.next(true));
        }
        // continue otherwise
        if (!this.config.behavior.restrictPartChange || this.completed()) {
            this.partChange.emit({ index: this.selected + 1, source: 'button-next' });
            this.scrollToTop();
        }
    }

    prev(): void {
        if (this.selected > 0) {
            this.partChange.emit({ index: this.selected - 1, source: 'button-prev' });
            this.scrollToTop();
        }
    }

    reset() {
        this.partReset.emit(this.model[this.selected]);
    }

    resettable(): boolean {
        const part = this.model[this.selected];
        if (part) {
            // disable button if all questions in selected part are unanswered or disabled
            const empty = ({
                questions,
                subGroups,
            }: {
                questions?: QuestQuestion[];
                subGroups?: QuestQuestionGroup[];
            }): boolean => {
                if (questions) {
                    const allQuestionsEmpty = questions.every((q) => !q.value || q.disabled);
                    if (!allQuestionsEmpty) {
                        return false;
                    }
                }
                if (subGroups) {
                    const allSubGroupsEmpty = subGroups.every((subGroup) => empty({ questions: subGroup.questions }));
                    if (!allSubGroupsEmpty) {
                        return false;
                    }
                }
                return true;
            };
            if (empty(part) && (!part.groups || part.groups.every(empty))) {
                return false;
            }
        }
        const id = part?.id;
        return this.config.behavior.resettableParts.disabledFor?.indexOf(id) === -1;
    }

    submit(run?: boolean): void {
        // wait until potential check
        if (!run && this.config.behavior.waitForCheck) {
            return this.setPending(() => this.submit(true));
        }
        // continue otherwise
        if (this.completed() || !this.config.behavior.restrictSubmit) {
            if (this.config.behavior.useCaptcha) {
                if (
                    (!this.config.captcha.version || this.config.captcha.version === 'v2') &&
                    this.captcha &&
                    !this.captcha.getResponse()
                ) {
                    // recaptcha v2 flow
                    // disable submit button to prevent double submit
                    this.submitDisabled = true;
                    // first validate captcha, submit will be called from handleCaptcha....
                    return this.config.captcha.size === 'invisible' && this.captcha.execute();
                }
                if (this.config.captcha.version === 'v3') {
                    // recaptcha v3 flow
                    this.recaptchaV3Service.execute('submit').subscribe(
                        (token) => {
                            this.check.emit({
                                id: 'captcha',
                                value: token,
                                valid: true,
                                part: this.model[this.selected],
                            });
                            this.partChange.emit();
                            this.scrollToTop();
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                    // disable submit button to prevent double submit
                    this.submitDisabled = true;
                    return;
                }
            }

            this.partChange.emit();
            this.scrollToTop();
        }
    }

    exit(): void {
        this.leave.emit();
    }

    /**
     * Track by function - used as each part shall not be re-rendered on model change
     *
     * @param index
     * @param item
     */
    id(idx: number, item: { id: string }): string {
        return item.id;
    }

    /**
     * scroll to top
     */
    scrollToTop() {
        if (this.config.behavior.scrollOnPartChange) {
            const behavior = this.config.behavior.scrollOnPartChange as ScrollBehavior;
            window.scrollTo({ top: 0, left: 0, behavior });
        }
    }

    /**
     * handle captcha response
     * @param response
     */
    handleCaptcha(response?: any) {
        this.check.emit({ id: 'captcha', value: response, valid: true, part: this.model[this.selected] });
        if (this.config.captcha.size === 'invisible') {
            this.submit();
            this.captcha.reset();
        }
    }

    /**
     * resets captcha response on expire
     * @param response
     */
    expired() {
        this.captcha.reset();
    }

    protected setPending(action?: () => void): void {
        // set pending action only if "initial" pending was set
        if (this.pending || (!this.pending && !action)) {
            this.pending = { action };
        } else if (action) {
            // call action immediately otherwise
            action();
        }
    }

    protected processPending(): void {
        if (this.pending && this.pending.action) {
            this.pending.action();
        }
        this.pending = undefined;
    }

    protected validateAllQuestions(): boolean {
        return false;
    }

    markNonValidParts() {
        this.model.forEach((part, index) => {
            part.valid = this.completed(index);
        });
    }

    private recurseGroups(group: QuestQuestionGroup): QuestQuestionGroup[] {
        const subs = (group.subGroups || []).map((g) => this.recurseGroups(g));
        return [group, ...flatten(subs)];
    }

    protected completed(index?: number): boolean {
        let questions: QuestQuestion[];
        if (this.validateAllQuestions()) {
            // collect all questions in questionnaire
            const groups: QuestQuestionGroup[] = flatten(this.model.map((part) => part.groups || []));
            const allGroups: QuestQuestionGroup[] = flatten(groups.map((g) => g.subGroups || [])).concat(groups);

            questions = flatten(allGroups.map((g) => g.questions || [])).concat(
                flatten(this.model.map((part) => part.questions || []))
            );
        } else {
            const current = index !== undefined ? this.model[index] : this.model[this.selected];
            const currentQuestions = current.questions || [];
            const currentGroups = current.groups || [];
            const allCurrentGroups = flatten(currentGroups.map((g) => this.recurseGroups(g)));
            questions = [...currentQuestions, ...flatten(allCurrentGroups?.map((g) => g.questions || []))];
        }

        const required = (question: QuestQuestion) => question.validate && question.validate.required;

        // and set errors to all required if not checked yet explicitely
        // TODO: add re-captcha check in visible mode
        let valid = true;
        if (this.config.behavior.restrictPartChange || this.markErrors) {
            questions.forEach((question) => {
                if (question.error || (!question.value && required(question))) {
                    question.error = question.error || true;
                    valid = false;
                }
            });
        }
        // scroll to first invalid - deferred to have mat-error rendered - only in restrictive part change
        if (!valid && this.config.behavior.restrictPartChange) {
            setTimeout(() => {
                const invalid = this.element.querySelector('mat-error:not(:empty), .error-for-disabled:not(:empty)');
                this.scrollTo((invalid && invalid.closest && invalid.closest('vi-quest-question')) || invalid);
            });
        }

        return valid;
    }

    protected scrollTo(element: Element): void {
        if (element) {
            if (browser.ie()) {
                const rects = element.getClientRects();
                // fix IE to not scroll horizontally
                window.scrollBy(0, (rects.length && rects[0].top) || 0);
            } else {
                element.scrollIntoView({ behavior: 'smooth', inline: 'end' });
            }
        }
    }
}
