import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

import { QuestConfig } from './quest-config.interface';
import { QuestService } from './quest-service.interface';
import { Prefixable } from './prefixable.interface';
import { Quest } from './quest.interface';
import { QuestValueChange } from './quest-value-change.interface';
import { QuestPartChange } from './quest-part-change.interface';
import { QuestPart } from './quest-part.interface';
import { QuestPartReset } from './quest-part-reset.interface';

/**
 * Quest component rendering quest parts according to the model.
 *
 * @param model Quest model to observe
 * @param service
 * @param id
 * @param params
 *
 * @event submit
 * @event check
 * @event partChange
 *
 * @example
 * <vi-quest [model]="model"></vi-quest>
 * <vi-quest [service]="quests" [id]="some.id"></vi-quest>
 *
 * @note Handling service, id, params not implemented yet
 */

@Component({
    selector: 'vi-quest',
    templateUrl: './quest.component.html',
    styleUrls: ['./quest.component.scss'],
})
export class QuestComponent extends Prefixable implements OnInit {
    @Input() model?: Observable<Quest>;
    @Input() service?: QuestService;
    @Input() id?: string | number;
    @Input() params?: any;
    @Input() part = 0;

    @Output() check: EventEmitter<QuestValueChange> = new EventEmitter();
    @Output() partChange: EventEmitter<QuestPartChange> = new EventEmitter();
    @Output() reset: EventEmitter<QuestPartReset> = new EventEmitter();
    @Output() submit: EventEmitter<Quest | Promise<Quest>> = new EventEmitter();
    @Output() leave: EventEmitter<string> = new EventEmitter<string>();

    quest: Quest;
    captcha: string;

    checking: boolean;

    markErrors = false;

    constructor(private config: QuestConfig) {
        super(config && config.context);
    }

    ngOnInit() {
        if (this.model) {
            this.model.subscribe((model: Quest) => {
                this.quest = model;
                // make sure that part set via input parameter is not set too high:
                // reset to 0 if one of the parts before are not valid
                if (
                    this.part &&
                    model.parts.slice(0, this.part - 1).some((p) => !p.valid) &&
                    this.config.behavior.restrictPartChange
                ) {
                    this.part = 0;
                }
                // enables ui controls after check event
                if (this.config.behavior.disableOnCheck) {
                    this.checking = false;
                }
                // set active part first enabled if first disabled
                if (model.parts[0]?.disabled) {
                    // deferred as can be new injected and must be rendered before selection
                    setTimeout(() => {
                        this.part =
                            model.parts
                                .map((item, idx) => !item.disabled && idx)
                                .filter(Boolean)
                                .shift() || 0;
                    });
                }
            });
        } else if (this.service) {
            throw Error('Not implemented yet...');
        }
    }

    changePart(change: QuestPartChange) {
        if (change === undefined) {
            // last part done
            this.markErrors = !this.config.behavior.restrictPartChange;
            this.submit.emit({ ...this.quest, captcha: this.captcha });
        } else if (
            (this.quest.parts[change.index] && !this.quest.parts[change.index].disabled) ||
            !this.config.behavior.restrictPartChange
        ) {
            this.part = change.index;
            this.partChange.emit({ ...change, quest: this.quest });
        }
    }

    resetPart(part: QuestPart) {
        this.reset.emit({ part, quest: this.quest });
    }

    changed(answer: QuestValueChange) {
        if (answer.id === 'captcha') {
            // terminate captcha response
            this.captcha = answer.value;
        } else {
            // Disables controls during quest lib check event
            if (this.config.behavior.disableOnCheck) {
                this.checking = true;
            }
            // emit otherwise
            this.check.emit({ ...answer, quest: this.quest });
        }
    }

    exit() {
        this.leave.emit();
    }
}
