import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxUploaderModule } from 'ngx-uploader';
import { ReCaptchaModule } from 'angular2-recaptcha';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { TrustHtmlPipe } from './utils/trust-html.pipe';
import { QuestConfig } from './quest-config.interface';
import { QuestComponent } from './quest.component';
import { QuestPartsComponent } from './quest-parts/quest-parts.component';
import { QuestPartsSingleComponent } from './quest-parts/quest-parts-single.component';
import { QuestPartsTabbedComponent } from './quest-parts/quest-parts-tabbed.component';
import { QuestPartsSteppedComponent } from './quest-parts/quest-parts-stepped.component';
import { QuestPartsSteppedStepComponent } from './quest-parts/quest-parts-stepped-step.component';
import { QuestPartsWizardComponent } from './quest-parts/quest-parts-wizard.component';
import { QuestQuestionComponent } from './quest-question/quest-question.component';
import { QuestQuestionAnsweredComponent } from './quest-question/quest-question-answered.component';
import { QuestAnswerComponent } from './quest-answer/quest-answer.component';
import { QuestAnswerCardComponent } from './quest-answer/quest-answer-card.component';
import { QuestAnswerTextComponent } from './quest-answer/quest-answer-text.component';
import { QuestAnswerDropdownComponent } from './quest-answer/quest-answer-dropdown.component';
import { QuestAnswerRadioComponent } from './quest-answer/quest-answer-radio.component';
import { QuestAnswerChecklistComponent } from './quest-answer/quest-answer-checklist.component';
import { QuestAnswerCheckboxComponent } from './quest-answer/quest-answer-checkbox.component';
import { QuestAnswerDateComponent } from './quest-answer/quest-answer-date.component';
import { QuestAnswerNumberComponent } from './quest-answer/quest-answer-number.component';
import { QuestAnswerFileComponent } from './quest-answer/quest-answer-file.component';
import { QuestAnswerHintComponent } from './quest-answer/quest-answer-hint.component';
import { QuestGroupComponent } from './quest-group/quest-group.component';
import { QuestHintComponent } from './quest-hint/quest-hint.component';
import { QuestHintDialogComponent } from './quest-hint/quest-hint-dialog.component';
import { QuestValidationIconComponent } from './quest-validation/quest-validation-icon.component';
import { QuestValidationErrorDirective } from './quest-validation/quest-validation-error.directive';
import { IconComponent } from './utils/icon.component';
import { QuestAnswerSuggestComponent } from './quest-answer/quest-answer-suggest.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

/**
 * QuestModule.
 *
 * @example
 * ```ts
 * import { QuestModule, QuestConfig } from '@vi/quest';
 *
 * // defines quest config - @see QuestConfig
 * const questConfig: QuestConfig = {
 *   context: '',
 *   icons: {
 *     valid: 'check',
 *     invalid: 'close',
 *   },
 * };
 *
 * @NgModule({
 *   // ...
 *  imports: [
 *    QuestModule.forRoot(questConfig),
 * })
 * export class AppModule { }
 * ```
 *
 * @note Library uses translations (without context) mentioned in sources as
 * ` @translate some.key.you.have.to.provide.but.with.context `
 * You can get all (except dynamic like "named" submit) by
 * `npm run phraseapp:keys`
 *
 * @note Date implementation and date formatting as used in datepicker of quest-answer-date component
 * can be set by application (f.e. NativeDateAdapter - @angular/materials,
 * MomentDateAdapter - @angular/material-moment-adapter or some CustomDateAdapter).
 * Date values are returned in changed event as ISO 8601 formatted string.
 *
 * @example
 * ```ts
 * import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
 * import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
 *
 * @NgModule({
 *   // ...
 *  providers: [
 *    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
 *    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
 *    // ...
 * })
 * export class AppModule { }
 * ```
 *
 * More information about DateAdapter and its usage can be found here:
 * https://material.angular.io/components/datepicker/overview#choosing-a-date-implementation-and-date-format-settings
 *
 *
 */

const materialModules = [
    MatTabsModule,
    MatStepperModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
];

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        NgxUploaderModule,
        ReCaptchaModule,
        RecaptchaV3Module,
        ...materialModules,
    ],
    declarations: [
        TrustHtmlPipe,
        QuestComponent,
        QuestPartsComponent,
        QuestPartsSingleComponent,
        QuestPartsTabbedComponent,
        QuestPartsSteppedComponent,
        QuestPartsSteppedStepComponent,
        QuestPartsWizardComponent,
        QuestQuestionComponent,
        QuestQuestionAnsweredComponent,
        QuestAnswerComponent,
        QuestAnswerCardComponent,
        QuestAnswerTextComponent,
        QuestAnswerDropdownComponent,
        QuestAnswerRadioComponent,
        QuestAnswerChecklistComponent,
        QuestAnswerCheckboxComponent,
        QuestAnswerDateComponent,
        QuestAnswerNumberComponent,
        QuestAnswerFileComponent,
        QuestAnswerHintComponent,
        QuestGroupComponent,
        QuestHintComponent,
        QuestHintDialogComponent,
        QuestValidationIconComponent,
        QuestValidationErrorDirective,
        IconComponent,
        QuestAnswerSuggestComponent,
    ],
    exports: [
        QuestComponent,
        QuestAnswerCardComponent,
        QuestQuestionComponent,
        QuestAnswerCheckboxComponent,
        QuestAnswerChecklistComponent,
        QuestAnswerDateComponent,
        QuestAnswerDropdownComponent,
        QuestAnswerHintComponent,
        QuestAnswerNumberComponent,
        QuestAnswerRadioComponent,
        QuestAnswerSuggestComponent,
        QuestAnswerTextComponent,
        QuestPartsSingleComponent,
        QuestPartsTabbedComponent,
        QuestPartsSteppedComponent,
        QuestPartsWizardComponent,
        QuestHintComponent,
    ],
})
export class QuestModule {
    static forRoot(config: QuestConfig = {}): ModuleWithProviders<QuestModule> {
        // removed spread syntax based merging with defaultConfig due to aot compatibility issues
        // details see https://github.com/angular/angular/issues/28078
        return {
            ngModule: QuestModule,
            providers: [
                {
                    provide: QuestConfig,
                    useValue: {
                        context: config.context || '',
                        icons: {
                            valid: config.icons?.valid || 'check',
                            invalid: config.icons?.invalid || 'close',
                            file: config.icons?.file || 'description_outline',
                            enabled: config.icons?.enabled !== false,
                        },
                        behavior: {
                            hideEmptyGroups: config.behavior?.hideEmptyGroups !== false,
                            checkInvalid: config.behavior?.checkInvalid !== false,
                            nextOnWizard: config.behavior?.nextOnWizard || false,
                            scrollOnPartChange: config.behavior?.scrollOnPartChange || 'smooth',
                            hideDisabledOptions: config.behavior?.hideDisabledOptions !== false,
                            allowAnswerReset: config.behavior?.allowAnswerReset || false,
                            answerResetLabel: config.behavior?.answerResetLabel || '',
                            suggestMinChars: config.behavior?.suggestMinChars || 2,
                            suggestMaxEntries: config.behavior?.suggestMaxEntries || 10,
                            suggestShowMoreLabel: config.behavior?.suggestShowMoreLabel || '...',
                            useCaptcha: config.behavior?.useCaptcha || false,
                            waitForCheck: config.behavior?.waitForCheck !== false,
                            disableOnCheck: config.behavior?.disableOnCheck || false,
                            tooltipForDisabled: config.behavior?.tooltipForDisabled || '',
                            resettableParts: {
                                enabled: config.behavior?.resettableParts?.enabled || false,
                                disabledFor: config.behavior?.resettableParts?.disabledFor || [],
                            },
                            alwaysShowPrevNext: config.behavior?.alwaysShowPrevNext || false,
                            wizardFooter: config.behavior?.wizardFooter || false,
                            restrictPartChange: config.behavior?.restrictPartChange !== false,
                            restrictSubmit: config.behavior?.restrictSubmit !== false,
                            enterButton: config.behavior?.enterButton || false,
                            floatInputIncrement: config.behavior?.floatInputIncrement || 0.01,
                            integerInputIncrement: config.behavior?.integerInputIncrement || 1,
                            showTextInsteadOfGroupTooltip: config.behavior?.showTextInsteadOfGroupTooltip || false,
                            showImagePlaceholder: config.behavior?.showImagePlaceholder || false,
                            numberDisableSteps: config.behavior?.numberDisableSteps || false,
                        },
                        captcha: config.captcha,
                        file: config.file,
                        hint: {
                            useDialog: config.hint?.useDialog || 1083,
                            hintOnMouseOver: config.hint?.hintOnMouseOver || false,
                        },
                    },
                },
                {
                    provide: RECAPTCHA_V3_SITE_KEY,
                    useValue: (config.captcha && config.captcha.siteKey) || '',
                },
            ],
        };
    }
}
