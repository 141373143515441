import { AbstractControl, ValidatorFn, Validators, ValidationErrors } from '@angular/forms';
import { Moment } from 'moment';
import { DateUtils } from '../utils/DateUtils';

/**
 * Custom integer validation
 */
function validateInteger(control: AbstractControl): ValidationErrors | null {
    const invalid = Validators.pattern(/^-?\d+$/)(control);
    return invalid ? { integer: true } : null;
}

/**
 * Custom float validation
 */
function validateFloat(control: AbstractControl): ValidationErrors | null {
    const invalid = Validators.pattern(/^-?\d+[.,]*\d*$/)(control);
    return invalid ? { float: true } : null;
}

/**
 * Custom date validation (assuming moment date adapter is used)
 * Sets invalid as long as date is incomplete in input
 */
function validateDate(control: AbstractControl): ValidationErrors | null {
    const value = control.value as Moment;
    const unused = value && value.parsingFlags && value.parsingFlags().unusedTokens;
    const invalid = value && unused && unused.length !== 0;

    return invalid ? { date: true } : null;
}

function validateMaxDate(timeSpan: string): ValidatorFn {
    return (control: AbstractControl) => {
        if (control.value && timeSpan) {
            const value = control.value as Moment;
            const maxDate = DateUtils.parseDateMax(timeSpan);
            const invalid = maxDate.isBefore(value);
            return invalid ? { dateMax: { date: maxDate.format('DD.MM.YYYY') } } : null;
        }
        return null;
    };
}

function validateMinDate(timeSpan: string): ValidatorFn {
    return (control: AbstractControl) => {
        if (control.value && timeSpan) {
            const value = control.value as Moment;
            const minDate = DateUtils.parseDateMin(timeSpan);
            const invalid = minDate.isAfter(value);
            return invalid ? { dateMin: { date: minDate.format('DD.MM.YYYY') } } : null;
        }
        return null;
    };
}

function validateDecimalPlaces(decimals: number): ValidatorFn {
    return (control: AbstractControl) => {
        if (control.value && decimals) {
            const regex = new RegExp(`^-?\\d+(?:[.,]\\d{1,${decimals}})?$`);
            const invalid = !regex.test(control.value);
            return invalid ? { decimalPlaces: { decimals } } : null;
        }
        return null;
    };
}

/**
 * Quest validators.
 */
export const QuestValidators: { [key: string]: (param?: any) => ValidatorFn } = {
    // @translate error.required
    required: () => Validators.required,
    // @translate error.email
    email: () => Validators.email,
    // @translate error.integer
    integer: () => validateInteger,
    // @translate error.float
    float: () => validateFloat,
    // @translate error.min
    min: (min: number) => Validators.min(min),
    // @translate error.max
    max: (max: number) => Validators.max(max),
    // @translate error.date
    date: () => validateDate,
    // @translate error.dateMax
    dateMax: (max: string) => validateMaxDate(max),
    // @translate error.dateMin
    dateMin: (min: string) => validateMinDate(min),
    // @translate error.decimalPlaces
    decimalPlaces: (decimals: number) => validateDecimalPlaces(decimals),

    // some: someCustom(), // placeholder for some custom

    // custom: undefined, // do not use as it is used for QuestQuestion.error handling
};
