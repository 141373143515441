<mat-form-field *ngIf="control" floatLabel="never">
    <input
        matInput
        type="number"
        [step]="type === 'float' ? config.behavior.floatInputIncrement : config.behavior.integerInputIncrement"
        [formControl]="control"
        [name]="id"
        [placeholder]="placeholder"
        (change)="changed($event)"
        (focus)="focused(true, $event)"
        (click)="focused(true, $event)"
        (blur)="focused(false, $event)"
        (keyup.enter)="enter($event)"
        (keydown.enter)="$event.preventDefault()"
        (keyup)="keyup($event)"
        (keydown.arrowUp)="config.behavior.numberDisableSteps ? $event.preventDefault() : ''"
        (keydown.arrowDown)="config.behavior.numberDisableSteps ? $event.preventDefault() : ''"
        (wheel)="config.behavior.numberDisableSteps ? $event.preventDefault() : ''"
    />
    <button
        *ngIf="config.behavior.enterButton && control.value && !control.disabled && question.focused"
        mat-icon-button
        matSuffix
        class="enter"
    >
        <mat-icon>subdirectory_arrow_left</mat-icon>
    </button>
    <button
        *ngIf="config.behavior.allowAnswerReset && control.value && !control.disabled"
        tabindex="-1"
        matSuffix
        mat-icon-button
        (click)="clearValue($event)"
    >
        <mat-icon>close</mat-icon>
    </button>
    <mat-error [viQuestValidationError]="control"></mat-error>
</mat-form-field>
<div
    class="error-for-disabled"
    *ngIf="control.hasError('custom') && disabled"
    [innerHTML]="control.getError('custom')"
></div>
