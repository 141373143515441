import { Component } from '@angular/core';
import { Quest } from '../../../../../quest/src/lib/quest.interface';
import { QuestDisplayType } from '../../../../../quest/src/lib/quest-display-type.enum';
import { QuestionType } from '../../../../../quest/src/lib/question-type.enum';
import { QuestionAppearance } from '../../../../../quest/src/lib/question-appearance.enum';
import { BehaviorSubject } from 'rxjs';
import { QuestValueChange } from '../../../../../quest/src/lib/quest-value-change.interface';
import { QuestPartChange } from '../../../../../quest/src/lib/quest-part-change.interface';

const cardModel: Quest = {
    id: 'TestCardModel',
    display: QuestDisplayType.single,
    // Not neccessary for the QuestLib
    original: undefined,
    parts: [
        {
            id: 'PartCard',
            title: 'Card',
            questions: [
                {
                    id: 'TestQuestions01',
                    type: QuestionType.card,
                    text: 'To be or not to be?',
                    value: 'TestValue01',
                    options: [
                        {
                            id: 'TestQuestionOption01',
                            text: 'Option01',
                            image:
                                'https://api-integration.viessmann-climatesolutions.com/backend/configuration/v1/configit/' +
                                'images?imagePath=images%5CEnergySources%5COil.png&maxHeight=300',
                        },
                        {
                            id: 'TestQuestionOption02',
                            text: '<h1>Option02</h1>',
                            description: 'Test Description',
                        },
                        {
                            id: 'TestQuestionOption03',
                            text: 'Option03',
                            image:
                                'https://api-integration.viessmann-climatesolutions.com/backend/configuration/v1/configit/' +
                                'images?imagePath=images%5CEnergySources%5COil.png&maxHeight=300',
                            description: 'Test Description',
                            // Can be used for adding custom css classes
                            custom: 'testClass',
                        },
                    ],
                    error: false,
                    appearance: QuestionAppearance.full,
                },
            ],
        },
    ],
};

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class CardComponent {
    public panelOpenState = false;

    public model = cardModel;

    public quest: BehaviorSubject<Quest> = new BehaviorSubject<Quest>(this.model);

    constructor() {}

    check(changed: QuestValueChange) {
        console.log('SomethingChanged');
        console.log(changed);
    }

    page(changed: QuestPartChange) {
        // TODO: do something here
        console.log('next/back was pressed!');
    }

    submit(model: any) {
        // TODO: decide what to do here
        console.log('SUBMIT!');
    }

    private onError(translationKey: string) {
        // TODO: decide what to do with errors
        console.log('error');
    }
}
