import { QuestDisplayType } from '../../../quest/src/public_api';

export const environment = {
    production: false,
    configit: {
        baseUrl: 'https://api-integration.viessmann.com/backend/configuration/v1/configit',
        imageUrl: 'https://api-integration.viessmann.com/backend/configuration/v1/configit/images',
        languages: ['de'],
        salesAreaId: '0500/01/01',
        salesAreaName: 'Germany',
    },
    translations:
        'https://api-integration.viessmann.com/translations/v2/phraseapp-proxy/projects/c7e936c86cbb53e8813129c35bfbe7f8/locales/',
    defaultLanguage: 'de-DE',

    quest: {
        context: 'quest',
        defaultModel: 'ProductFinder',
        configurationDisplayType: QuestDisplayType.single,
    },
    app: {
        settingsPartId: 'Settings',
        progressId: 'Settings.Progress'
    }
};
